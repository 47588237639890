import Empty from "@/components/empty.vue";
import Layout from "@/layout";
export default {
  path: "/logistics",
  name: "Logistics",
  component: Layout,
  meta: { title: "物流管理" },
  children: [
    {
      path: 'way',
      component: Empty,
      name: 'way',
      parentName: 'Logistics',
      meta: { title: '线上物流' },
      children: [
        {
          path: 'logisticsOrder',
          name: 'LogisticsOrder',
          parentName: 'way',
          fullPath: '/logistics/way/logisticsOrder',
          component: () => import('@/views/Logistics/LogisticsOrder/index.vue'),
          meta: { title: '物流订单' }
        },
        {
          path: 'logisticsDetail',
          name: 'LogisticsDetail',
          parentName: 'way',
          isHide: true,
          fullPath: '/logistics/way/logisticsDetail',
          component: () => import('@/views/Logistics/LogisticsOrder/detail.vue'),
          meta: { title: '物流订单详情' }
        },
        {
          path: 'place',
          name: 'Place',
          parentName: 'way',
          isHide: true,
          fullPath: '/logistics/way/logisticsDetail',
          component: () => import('@/views/Logistics/LogisticsOrder/place.vue'),
          meta: { title: '物流下单' }
        },
        {
          path: 'edit',
          name: 'logisticsEdit',
          parentName: 'way',
          isHide: true,
          fullPath: '/logistics/way/logisticsDetail',
          component: () => import('@/views/Logistics/LogisticsOrder/edit.vue'),
          meta: { title: '物流订单详情' }
        },
        {
          path: 'onlineWaybillList',
          name: 'OnlineWaybillList',
          parentName: 'way',
          fullPath: '/logistics/way/onlineWaybillList',
          component: () => import('@/views/Logistics/OnlineWaybill/list.vue'),
          meta: { title: '物流承运单' }
        },
        {
          path: 'onlineWaybill',
          name: 'OnlineWaybill',
          parentName: 'way',
          isHide: true,
          fullPath: '/logistics/way/onlineWaybill',
          component: () => import('@/views/Logistics/OnlineWaybill/index.vue'),
          meta: { title: '承运详情', activeMenu: '/logistics/way' }
        },
        {
          path: 'logisticsMap',
          name: 'logisticsMap',
          parentName: 'way',
          isHide: true,
          fullPath: '/logistics/way/logisticsMap',
          component: () => import('@/views/Logistics/OnlineWaybill/logisticsMap.vue'),
          meta: { title: '运单信息', isPublic: true, activeMenu: '/logistics/way' }
        },
        {
          path: 'wayPay',
          name: 'WayPay',
          parentName: 'way',
          isHide: true,
          fullPath: '/logistics/way/logisticsMap',
          component: () => import('@/views/Logistics/OnlineWaybill/pay.vue'),
          meta: { title: '运单支付', isPublic: true, activeMenu: '/logistics/way' }
        },
      ]
    },
    // 财务管理
    {
      path: "financeManage",
      component: Empty,
      name: "FinanceManage",
      parentName: "Logistics",
      meta: { title: "财务管理" },
      children: [
        {
          path: 'payList',
          name: 'PayList',
          parentName: 'FinanceManage',
          fullPath: '/logistics/financeManage/payList',
          component: () => import('@/views/Logistics/OnlineWaybill/payList.vue'),
          meta: { title: '运费管理' }
        },
        {
          path: 'dealManagement',
          name: 'DealManagement',
          parentName: 'FinanceManage',
          fullPath: '/logistics/financeManage/dealManagement',
          component: () => import('@/views/Logistics/OnlineWaybill/dealManagement.vue'),
          meta: { title: '应付管理' }
        },
        {
          path: 'payRecords',
          name: 'PayRecords',
          parentName: 'FinanceManage',
          fullPath: '/logistics/financeManage/payRecords',
          component: () => import('@/views/Logistics/OnlineWaybill/payRecords.vue'),
          meta: { title: '支付记录' }
        }
      ]
    },
    // 物流发票
    {
      path: "invoice",
      component: Empty,
      name: "Invoice",
      parentName: "Logistics",
      meta: { title: "物流发票" },
      children: [
        //     // 订单提货
        {
          path: 'logisticsInvoice',
          name: 'LogisticsInvoice',
          parentName: 'Invoice',
          fullPath: '/logistics/invoice/logisticsInvoice',
          component: () => import('@/views/Logistics/OnlineWaybill/logisticsInvoice.vue'),
          meta: { title: '开票申请' }
        },
        {
          path: 'invoiceRecords',
          name: 'InvoiceRecords',
          parentName: 'Invoice',
          fullPath: '/logistics/invoice/invoiceRecords',
          component: () => import('@/views/Logistics/OnlineWaybill/invoiceRecords.vue'),
          meta: { title: '申请记录' }
        },
      ]
    },
    {
      path: "basic",
      component: Empty,
      name: "Basic",
      parentName: "Logistics",
      meta: { title: "基础数据" },
      children: [
        {
          path: "deliveryList",
          name: "BasicDeliveryList",
          parentName: "Basic",
          fullPath: "/logistics/basic/deliveryList",
          component: () => import("@/views/Logistics/Base/Delivery/index.vue"),
          meta: { title: "提货地址" },
        },
        {
          path: "deliveryDetail",
          name: "BasicDeliveryDetail",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/deliveryDetail",
          component: () => import("@/views/Logistics/Base/Delivery/detail.vue"),
          meta: {
            title: "提货地址详情",
            activeMenu: "/logistics/basic/deliveryList",
            isPublic: true,
          },
        },
        {
          path: "collectList",
          name: "BasicCollectList",
          parentName: "Basic",
          fullPath: "/logistics/basic/collectList",
          component: () => import("@/views/Logistics/Base/Collect/index.vue"),
          meta: { title: "收货地址" },
        },
        {
          path: "collectDetail",
          name: "BasicCollectDetail",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/collectDetail",
          component: () => import("@/views/Logistics/Base/Collect/detail.vue"),
          meta: {
            title: "收货地址详情",
            activeMenu: "/logistics/basic/collectList",
            isPublic: true,
          },
        },
        {
          path: "consigneeManage",
          name: "BasicConsigneeManage",
          parentName: "Basic",
          fullPath: "/logistics/basic/consigneeManage",
          component: () => import("@/views/Logistics/Base/Consignee/index.vue"),
          meta: { title: "提货人" },
        },
        {
          path: "consigneeEdit",
          name: "BasicConsigneeEdit",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/consigneeEdit",
          component: () => import("@/views/Logistics/Base/Consignee/edit.vue"),
          meta: {
            title: "提货人详情",
            activeMenu: "/logistics/basic/consigneeManage",
            isPublic: true,
          },
        },
        {
          path: "senderManage",
          name: "BasicSenderManage",
          parentName: "Basic",
          fullPath: "/logistics/basic/senderManage",
          component: () => import("@/views/Logistics/Base/Sender/index.vue"),
          meta: { title: "送货人" },
        },
        {
          path: "senderEdit",
          name: "BasicSenderEdit",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/senderEdit",
          component: () => import("@/views/Logistics/Base/Sender/edit.vue"),
          meta: {
            title: "送货人详情",
            activeMenu: "/logistics/basic/senderManage",
            isPublic: true,
          },
        },
      ],
    },
  ],
};
