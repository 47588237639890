import Layout from '@/layout'
import Empty from '@/components/empty.vue'


const tradeRouter = {
    path: '/trade',
    component: Layout,
    redirect: '/trade/apply',
    name: 'trade',
    meta: {
        title: '金融管理'
    },
    children: [
      {
        path: 'apply',
        component: Empty,
        name: 'apply',
        meta: {
          title: '赊销管理'
        },
        children: [
          {
            path: 'product',
            component: () => import('@/views/trade/apply/product/index.vue'),
            name: 'product',
            meta: {
              title: '赊销申请'
            }
          },
          {
            path: 'product/detail/:id',
            component: () => import('@/views/trade/apply/product/myDetail.vue'),
            name: 'productMyDetail',
            meta: {
              title: '我的申请详情',
              activeMenu: '/trade/apply/product'
            },
            isHide: true,
          },
          {
            path: 'product/received/:id',
            component: () => import('@/views/trade/apply/product/receivedDetail.vue'),
            name: 'productReceivedDetail',
            meta: {
              title: '已收申请详情',
              activeMenu: '/trade/apply/product'
            },
            isHide: true,
          },
          {
            path: 'quota',
            component: () => import('@/views/trade/apply/quota.vue'),
            name: 'quota',
            meta: {
              title: '赊销额度'
            }
          }
        ]
      },
      {
        path: 'bill',
        component: Empty,
        name: 'bill',
        parentName: 'trade',
        meta: {
          title: '商票管理'
        },
        children: [
          {
            path: 'ticket',
            component: () => import('@/views/trade/bill/ticket/index.vue'),
            name: 'ticket',
            parentName: 'bill',
            meta: {
              title: '票据管理'
            }
          },
          {
            path: 'ticket/detail/:id',
            component: () => import('@/views/trade/bill/ticket/detail.vue'),
            name: 'ticketDetail',
            parentName: 'bill',
            isHide: true,
            meta: {
              title: '票据详情',
              activeMenu: '/trade/bill/ticket'
            }
          },
          {
            path: 'ticket/transfer',
            component: () => import('@/views/trade/bill/transfer/index.vue'),
            name: 'ticketTransfer',
            parentName: 'bill',
            meta: {
              title: '背书转让'
            }
          },
          {
            path: 'ticket/transfer/:id',
            component: () => import('@/views/trade/bill/transfer/detail.vue'),
            name: 'ticketTransferDetail',
            parentName: 'bill',
            isHide: true,
            meta: {
              title: '背书转让详情',
              activeMenu: '/trade/bill/ticket/transfer'
            }
          },
          {
            path: 'voucher/:type/:id', // type cash: 兑付，pledge： 质押, repay: 还款
            component: () => import('@/views/trade/bill/voucher/index.vue'),
            name: 'uploadVoucher',
            parentName: 'bill',
            isHide: true,
            meta: {
              title: '上传凭证'
            }
          },
          {
            path: 'ticket/expiring',
            component: () => import('@/views/trade/bill/expiring/index.vue'),
            name: 'ticketExpiring',
            parentName: 'bill',
            meta: {
              title: '到期票据'
            }
          },
          {
            path: 'ticket/expiring/:id',
            component: () => import('@/views/trade/bill/expiring/detail.vue'),
            name: 'ticketExpiringDetail',
            parentName: 'bill',
            isHide: true,
            meta: {
              title: '到期票据详情',
              activeMenu: '/trade/bill/ticket/expiring'
            }
          },
          {
            path: 'ticket/cash',
            component: () => import('@/views/trade/bill/cash/index.vue'),
            name: 'ticketCash',
            parentName: 'bill',
            meta: {
              title: '兑付管理'
            }
          },
          {
            path: 'ticket/cash/:id',
            component: () => import('@/views/trade/bill/cash/detail.vue'),
            name: 'ticketCashDetail',
            parentName: 'bill',
            isHide: true,
            meta: {
              title: '兑付管理',
              activeMenu: '/trade/bill/ticket/cash'
            }
          },
          // {
          //   path: 'hkgl',
          //   component: () => import('@/views/trade/sycdhp/hkgl.vue'),
          //   name: 'hkgl',
          //   parentName: 'sycdhp',
          //   meta: {
          //     title: '还款管理'
          //   }
          // }
        ]
      },
      {
        path: 'fee',
        component: Empty,
        name: 'Fee',
        meta: {
          title: '费用管理'
        },
        children: [
          {
            path: 'service',
            component: () => import('@/views/trade/fee/index.vue'),
            name: 'Service',
            meta: {
              title: '服务费管理'
            }
          },
          {
            path: 'guarantee',
            component: () => import('@/views/trade/fee/index.vue'),
            name: 'Guarantee',
            meta: {
              title: '保证金管理'
            }
          },
          {
            path: 'cost',
            component: () => import('@/views/trade/fee/index.vue'),
            name: 'Cost',
            meta: {
              title: '赊销成本'
            }
          }
        ]
      }
    ]
}

export default tradeRouter